.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.nnnn table thead {
  background: #f5f5f5;
}
.hr {
  /* border-top: 5px solid #52ace9;
  font-weight: bold; */
  border-bottom: 5px solid #3aa4f6;
  height: 0;
}
.conte {
  height: 200px;
}

.account {
  color: white;
  font-weight: bold;
}
.wechat {
  background-color: #52ace9;
  color: white;
  padding: 3px 59px;
  border: 1px solid;
  border-radius: 33px;
  margin: 4px;
}
.wechat2 {
  background-color: #52ace9;
  color: white;
  padding: 3px 92px;
  border: 1px solid;
  border-radius: 33px;
  margin: 4px;
}

.rowww {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 50px;
}
.rowwww {
  background: #f5f5f5;
  border-radius: 5px;
  padding-top: 20px;
}
.rowwww img {
  float: left;
}
.rowwww a {
  /* padding-top: 5px; */
}
.dataa {
  background-color: #52ace9;
  height: 200px;
  padding: 29px 0px;
}
.did p {
  color: white;
}
.fast_track {
  background: linear-gradient(to right, #239de6, #00d1ff);
  padding: 10px;
  width: 40%;
  border-radius: 5px 44px 44px 5px;
  color: white;
}
.cocc {
  background: #e6e3e3;
}
.change:hover {
  background: white !important;
  color: #52ace9 !important;
}
.btninactive {
  background: #52ace9;
  margin: 5px;
}

.btnactive {
  background: rgb(46, 235, 96) !important;
}

.makeHeight {
  height: 500px;
  overflow-y: scroll;
}

.chat_left {
  display: inline-block;
  width: 80%;
  color: white;
  background: rgb(158, 186, 254);
  border-radius: 7px;
  clear: both;
  margin: 3px;
  padding: 10px;
}

.chat_right {
  float: right;
  display: inline-block;
  width: 80%;
  padding: 10px;
  color: white;
  background: rgb(87, 122, 151);
  border-radius: 7px;
  margin: 3px;
}
.ddd {
  display: flow-root;
}
.relative {
  position: relative;
}
.fixed {
  /* position: fixed; */
}
.shows {
  display: block !important;
}
@media screen and (max-width: 600px) {
  .find .tab {
    display: none;
  }
}

.showss {
  display: table-row;
}
.hides {
  display: none;
}
.btn-one {
  background-color: #ffa214 !important;
}
.iconsite {
  text-align: left;
  color: #19acf7;
  padding-top: 5px;
}
#user_captcha_input {
  margin-right: 12px;
}
input:invalid {
  border: 2px solid red;
}
select:invalid {
  border: 2px solid red;
}
input[type="radio"]:invalid {
  border: 2px solid red;
}
